<template>
  <div
    class="content"
    style="
      min-height: 100vh;
      background: linear-gradient(100deg, #b79afa, #8867fe);
    "
  >
    <van-popup
      position="bottom"
      v-model="rechargeBtn"
      style="
        height: 25%;
        width: 100%;
        padding: 10px;
        border-radius: 20px 20px 0 0;
      "
    >
      <div style="width: 100%; text-align: center; margin-bottom: 40px">
        {{ $t("property.TopUp") }}
      </div>
      <van-field
        v-model="amount"
        type="number"
        :placeholder="
          i == 1
            ? `${$t('withdraw.current')}:${USDT.amount}USDT`
            : `${$t('withdraw.current')}:${TOKEN.amount}UNA`
        "
        class="input"
        :border="false"
      >
        <template #extra>
          <div class="all">
            <div class="name">{{ i == 1 ? "USDT" : "CLS" }}</div>
          </div>
        </template>
      </van-field>
      <div class="lowest">
        <span style="margin-right: 27px"
          >{{ $t("property.balance") }}：{{ 0.1 }}</span
        >
      </div>
      <div>
        <van-button
          :hairline="true"
          :round="true"
          color="#8f92fa"
          style="width: 100%; border: 1px solid #000 !important"
          @click="withDrawHandle"
          >{{ $t("property.submit") }}</van-button
        >
      </div>
    </van-popup>
    <navbar @asideShow="asideFlagFun"></navbar>
    <div class="main">
      <div class="card">
        <div class="title">{{ $t("property.assets") }}</div>
        <div class="num">${{ totalAmount }}</div>
        <div class="btn">
          <!-- <van-button color="#7F51FF" size="small" style="margin-right:20px" @click="topUp">{{$t('property.TopUp')}}</van-button> -->
          <van-button style="" color="#FFD035" size="small" @click="topUp"
            ><span style="color: black !important">{{
              $t("property.TopUp")
            }}</span>
          </van-button>
          <van-button
            style="color: white"
            color="#8f92fa"
            size="small"
            @click="toExtract"
            >{{ $t("property.cash") }}</van-button
          >
          <van-button
            style="color: white"
            color="#62bfbf"
            size="small"
            @click="toAccounts"
            >{{ $t("property.accounts") }}</van-button
          >
        </div>
      </div>

      <div class="info">
        <div class="title">{{ $t("property.info") }}</div>
        <div class="list">
          <div class="item">
            <div>
              <div>
                <van-image
                  width="29px"
                  height="29px"
                  :src="require('@/assets/cls/usdt-logo.png')"
                />
              </div>
              <div>USDT</div>
            </div>
            <div>{{ Number(USDT.amount).toFixed(2) }}</div>
          </div>
          <div class="item">
            <div>
              <div>
                <van-image
                  width="29px"
                  height="29px"
                  :src="require('@/assets/xxImage/index/BNB@3x.png')"
                />
              </div>
              <div>BNB</div>
            </div>
            <div style="text-align:right;">{{ Number(BNB.amount).toFixed(4) }} <div style="text-align: right;margin-top:5px;color:#a7a7a7;font-weight: 400;">$ {{ Number(BNB.price).toFixed(2) }}</div></div>
          </div>
          <div class="item">
            <div>
              <van-image
                width="32px"
                height="32px"
                style="margin-left: 5px"
                :src="require('@/assets/xxImage/index/uanx.png')"
              />
              <div>UNA</div>
            </div>
            <div>{{ Number(TOKEN.amount).toFixed(2) }} <div style="text-align: right;margin-top:5px;color:#a7a7a7;font-weight: 400;">$ {{ Number(TOKEN.price).toFixed(2) }}</div></div>
          </div>
        </div>
      </div>
    </div>
    <!-- <asidePopup
      @asideFlagShow="asideFlagShow"
      style="text-align: left"
      :asideFlag="asideFlag"
    ></asidePopup> -->
    <van-popup round v-model="popupShow" position="bottom" class="popup">
      <div class="title">{{ $t("property.TopUp") }}</div>
      <van-field
        v-model="number"
        type="number"
        :placeholder="$t('property.amount')"
      >
        <template #extra>
          <div
            style="
              font-size: 12px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #333333;
            "
          >
            UNA
          </div>
        </template>
      </van-field>
      <div class="balance">
        {{ $t("property.balance") }}: {{ balances }} UNA
      </div>
      <div class="btn">
        <van-button color="#00A8FF" size="small" @click="sub">{{
          $t("property.submit")
        }}</van-button>
      </div>
    </van-popup>
    <asidePopup
      v-if="asideFlag"
      @close="noShow"
      :shows="asideFlag"
    ></asidePopup>
  </div>
</template>
<script>
// import VConsole from "../vconsole.js";
import { ethers } from "ethers";

import asidePopup from "@/components/common/asidePopup";
import Web3 from "web3";
import { mapState } from "vuex";
import abi_U from "@/common/abi/abi_U.json";
import BigNumber from "bignumber.js";

export default {
  components: { asidePopup },
  computed: {
    ...mapState({
      // setting: (state) => state.setting,
      walletAddress: (state) => state.walletAddress,
    }),
    balances() {
      if (this.decimals) {
        let balance = BigNumber(this.balance)
          .div(Math.pow(10, this.decimals))
          .toFixed();
        return balance;
      }
    },
  },
  data() {
    return {
      switchMT: null,
      rechargeBtn: false,
      asideFlag: false,
      popupShow: false,
      number: "",
      USDT: {},
      BNB: {},
      TOKEN: {},
      amount: "",
      totalAmount: "",
      setting: [],
      balance: 0,
      decimals: 0,
      address: "",
      i: 1,
      wallet_address: "",
      contract_address: "",
    };
  },
  methods: {
    withDrawHandle() {},
    noShow() {
      this.asideFlag = false;
    },
    asideFlagFun() {
      this.asideFlag = true;
    },
    //跳转转账
    toAccounts() {
      this.$router.push("/accounts");
    },
    recharge() {
      this.rechargeBtn = true;
    },
    asideFlagShow(e) {
      this.asideFlag = e;
    },
    topUp() {
      // this.getBalance();
      this.popupShow = true;
    },
    toExtract() {
      this.$router.push("/withdraw");
    },
    // 资产列表
    getMyEarn(type, mg) {
      if (type == "ok") {
        let tx = setInterval(() => {
          this.$axios.get(this.$api.myEarn, {}).then((res) => {
            if (this.totalAmount != res.data.totalAmount) {
              clearInterval(tx);
              this.number = "";
              this.popupShow = false;
              this.getBalance();
              this.$toast.clear();
              this.$toast(mg.msg);
            }
            this.totalAmount = res.data.totalAmount;
            window.localStorage.setItem('totalAmount', res.data.totalAmount)
            this.USDT = res.data.USDT;
            this.BNB = res.data.BNB;
            this.TOKEN = res.data.TOKEN;
          });
        }, 2000);
      } else {
        this.$axios.get(this.$api.myEarn, {}).then((res) => {
          this.totalAmount = res.data.totalAmount;
          this.USDT = res.data.USDT;
          this.BNB = res.data.BNB;
          this.TOKEN = res.data.TOKEN;
        });
      }
    },
    //获取TW对象
    getTrustWalletFromWindow() {
      const isTrustWallet = (ethereum) => {
        // Identify if Trust Wallet injected provider is present.
        const trustWallet = !!ethereum.isTrust;
        return trustWallet;
      };

      const injectedProviderExist =
        typeof window !== "undefined" && typeof window.ethereum !== "undefined";

      if (!injectedProviderExist) {
        return null;
      }

      if (isTrustWallet(window.ethereum)) {
        return window.ethereum;
      }

      if (window.ethereum?.providers) {
        return window.ethereum.providers.find(isTrustWallet) ?? null;
      }
      return window["trustwallet"] ?? null;
    },
    //TW查询精度
    async getdecimal() {
      this.setting.forEach((item) => {
        if (item.key == "coin_contract_address") {
          this.address = item.value;
        }
      });
      const injectedProvider = await this.getTrustWalletFromWindow();
      injectedProvider.request({ method: "wallet_switchEthereumChain", params: [ { chainId: process.env.VUE_APP_CHAIN_ID },],});
      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );
      const contracts = new ethers.Contract(
        this.address,
        abi_U,
        ethersProvider
      );
      const signer = ethersProvider.getSigner();
      //查询精度
      const decimasls = await contracts.decimals();
      this.decimals = ethers.BigNumber.from(decimasls).toNumber();
      //查询余额
      const con = await contracts.balanceOf(this.walletAddress);
      this.balance = ethers.BigNumber.from(con).toString();
    },
    // TW确认充值
    async topUpHandles() {
      const injectedProvider = await this.getTrustWalletFromWindow();
      injectedProvider.request({ method: "wallet_switchEthereumChain", params: [ { chainId: process.env.VUE_APP_CHAIN_ID },],});

      const ethersProvider = new ethers.providers.Web3Provider(
        injectedProvider
      );
      const signer = ethersProvider.getSigner();
      let toAddress = "";
      this.setting.forEach((item) => {
        if (item.key == "charge_coin_in_address") {
          toAddress = item.value;
        }
      });
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: this.$t("withdraw.create"),
      });
      let contract = new ethers.Contract(this.address, abi_U, ethersProvider);
      let amount =
        "0x" +
        BigNumber(this.number)
          .times(Math.pow(10, 18))
          .toString(16)
          .replace(/\.\w+$/, "");
      contract
        .connect(signer)
        .transfer(toAddress, amount)
        .then(
          (res) => {
            this.$axios
              .post(this.$api.recharge, {
                amount: this.number,
                tx_hash: res.hash,
              })
              .then((res) => {
                this.getMyEarn("ok", res);
              });
          },
          (err) => {
            this.$toast(this.$t("home.Recharge"));
          }
        );
    },

    // 查询余额、精度
    async getBalance() {
      this.setting.forEach((item) => {
        if (item.key == "coin_contract_address") {
          this.address = item.value;
        }
      });
      let contract = new web3.eth.Contract(abi_U, this.address, {
        from: this.walletAddress,
      });
      // 精度
      contract.methods
        .decimals()
        .call({ from: this.walletAddress }, (err, res) => {
          this.decimals = res;
        });
      // 余额
      contract.methods
        .balanceOf(this.walletAddress)
        .call({ from: this.walletAddress })
        .then((res) => {
          this.balance = res;
        });
    },
    // 充值按钮
    sub() {
      if (this.number.trim() == "") {
        this.$toast(this.$t("property.amount"));
      } else if (Number(this.number) > this.balance) {
        this.$toast(this.$t("property.deficiency"));
        this.topUpHandles();
      } else {
        this.$dialog
          .confirm({
            title: this.$t("property.TopUp"),
            message: this.$t("property.submitTopUp"),
            confirmButtonColor: "#00A8FF",
            confirmButtonText: this.$t("property.submit"),
            cancelButtonText: this.$t("property.cancel"),
          })
          .then(() => {
            if (this.switchMT == 1) {
              this.topUpHandle();
            } else if (this.switchMT == 2) {
              this.topUpHandles();
            }
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    // 确认充值
    async topUpHandle() {
      let toAddress = "";
      this.setting.forEach((item) => {
        if (item.key == "charge_coin_in_address") {
          toAddress = item.value;
        }
      });
      let contract = new web3.eth.Contract(abi_U, this.address, {
        from: this.walletAddress,
      });
      let amount =
        "0x" +
        BigNumber(this.number)
          .times(Math.pow(10, this.decimals))
          .toString(16)
          .replace(/\.\w+$/, "");
      contract.methods
        .transfer(toAddress, amount)
        .send({ from: this.walletAddress, to: toAddress }, (error, result) => {
          if (error) this.$toast(error.message);
          this.$axios
            .post(this.$api.recharge, {
              amount: this.number,
              tx_hash: result,
            })
            .then((res) => {
              this.$toast(res.msg);
              this.number = "";
              this.popupShow = false;
              this.getMyEarn();
            });
        });
    },
  },
  async mounted() {
    const injectedProvider = await this.getTrustWalletFromWindow();
    if(!injectedProvider.address){
                this.$store.commit("setwalletAddress", "");
                this.$store.commit("setDevotokens", "");
                this.$storage.remove("devotokens");
                this.$store.commit("setLinkBsc", false);
                this.$storage.clear();
    }
    this.switchMT = window.localStorage.getItem("switchMT");
    this.$store.dispatch("getSetting"); //网站配置
    this.getMyEarn();
    this.setting = JSON.parse(this.$store.state.setting);
    injectedProvider.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: process.env.VUE_APP_CHAIN_ID,
            },
          ],
        });
    // injectedProvider.request({ method: "eth_chainId" }).then((res) => {
    //   if (res != "0x38") {
    //     this.$toast(this.$t("public.bsclogin")); //请使用BSC登入
    //     //切换链ID
    //     injectedProvider.request({
    //       method: "wallet_switchEthereumChain",
    //       params: [
    //         {
    //           chainId: 0x38,
    //         },
    //       ],
    //     });
    //   }
    // });

    if (this.switchMT == 1) {
      this.getBalance();
    } else if (this.switchMT == 2) {
      this.getdecimal();
    }
  },
};
</script>
<style lang="scss" scoped>
.main {
  padding: 11px 15px 0;
  position: relative;
  .cardF {
    height: 145px;
    background: #e2e2ff;
    border-radius: 15px;
    border-bottom: 1px solid #000;
    margin-bottom: 15px;
  }
  .card {
    margin-bottom: 13px;
    // height: 140px;
    box-sizing: border-box;
    padding: 29px 23px 18px;
    // background: #e2e2ff;
    background: url("../assets/xxImage/index/BGcolr.png") no-repeat;
    background-size: 100% 100%;
    // border: 1px solid #000;
    border-radius: 15px;
    .title {
      font-size: 15px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #000;
    }
    .num {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #000;
      margin: 11px 0 19px;
    }
    .btn {
      display: flex;
      justify-content: space-between;
      button {
        width: 98px;
        height: 33px;
        border: 1px solid #000 !important;
        border-radius: 50px;
        box-shadow: inset 0 0px 5px rgba(255, 255, 255, 1);
        font-size: 12px;
        font-weight: bold;
      }
    }
  }
  .info {
    position: absolute;
    left: 0;
    height: calc(100vh - 226px);
    width: 100%;
    background: #ffffff;
    border-radius: 20px 20px 0px 0px;
    padding: 16px 15px;
    .title {
      font-size: 18px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333333;
      margin-bottom: 23px;
    }
    .list {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 26px;
        &:last-child {
          margin-bottom: 0;
        }
        > div:first-child {
          display: flex;

          align-items: center;
          div:last-child {
            font-size: 12px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            margin-left: 8px;
          }
        }
        > div:last-child {
          font-size: 12px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
        }
      }
    }
  }
}
.list {
  .cell-title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  /deep/.van-cell::after {
    border-bottom: 1px solid #eaeaea;
  }
}
.popup {
  padding: 16px 15px 25px;
  .title {
    font-size: 15px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #333333;
    margin-bottom: 31px;
    text-align: center;
  }
  .van-field {
    background: #ffffff;
    border: 1px solid #999999;
    border-radius: 5px;
  }
  .balance {
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #666666;
    margin: 8px 0 21px;
  }
  .btn {
    height: 35px;
    button {
      width: 100%;
      border-radius: 5px;
      background: linear-gradient(90deg, #b79afa, #8867fe) !important;
      border: 1px solid #000 !important;
      font-size: 15px;
    }
  }
}
.input {
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 5px;
  padding: 10px 10px 10px 0;
  .all {
    display: flex;
    align-items: center;

    .name {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
    .line {
      height: 16px;
      border-right: 1px solid #999999;
      margin: 0 11px;
    }
    .all_btn {
      font-size: 12px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #8d91f9;
    }
  }
}
.lowest {
  margin: 5px 0 20px;
  font-size: 10px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
}
.logo_border {
  width: 29px;
  height: 29px;
  border: 1px solid #000;
  // padding: 3px;
  vertical-align: middle;
  border-radius: 50%;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
